/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #0097E4;
	--color2:               #002B85;
	--color3:               #E0007A;
	--color4:               #AF001C;
	--color5:               #FFAC00;
	--color6:               #F8F6F1;
	--color7:               #7E94C1;

	// TYPOGRAPHY
	// ---------------

	--text-color:           #1A1818;
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.875rem;

	--font-family-primary:   "Noto Sans", Helvetica, Arial, sans-serif;
	--font-family-secondary: var(--font-family-primary);
	--font-family-headings:  var(--font-family-secondary);

	--hr-color:              #D1C6B0;

}